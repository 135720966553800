/* blog
*********************/

.blog{
  overflow: hidden;
}

  .blog__title{
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    color: #d43b12;
    margin: 30px 0;
  }

  .blog__post{
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #f6d4cb;

    &:last-child{
      margin-bottom: 0;
    }
  }

    .blog__post__img{
      min-width: 100%;
      width: 100%;
      height: 190px;
      border-radius: $rad;
    }

    .blog__post__title{
      margin: 0 0 15px 0;
    }

      .blog__post__title__link{
        padding-bottom: 2px;
        font-size: 18px;
        line-height: 30px;
        font-weight: 700;
        border-bottom: 1px solid #d2d2d2;
        color: #5c5b5b;
        text-decoration: none;
      }

    .blog__post__desc{
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #605f5f;
    }

    .all-post{
      margin-top: 22px;
      margin-bottom: 55px;
      text-align: center;
    }
    .all-post__link{
      font-size: 18px;
      line-height: 30px;
      color: #d43b12;
      font-weight: 700;
      text-decoration: none;
      border-bottom: 1px solid #d43b12;
    }