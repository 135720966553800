/* other */
.mainwrapper {
    background: url(../images/leftpanelbg.png) repeat-y 0 0;
}

.header {
    clear: both;
    height: 110px;
}

.leftpanel {
    width: 260px;
    color: #fff;
    float: left;
}

.rightpanel {
    margin-left: 260px;
}

.rightpanel:after {
    clear: both;
    content: '';
    display: block;
}

@media (min-width: 992px) and (max-width: 1199px) {

  .main-menu__item{
    margin-right: 20px;
  }

  #fixed,
  .fixed{
    width: 293.5px;
  }
}