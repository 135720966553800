/* breadcrumbs
******************/

.breadcrumbs{
  overflow: hidden;
}

  .breadcrumbs__link{
    display: inline-block;
    margin: 50px 5px 40px 0;
  }

  .breadcrumbs__link--back{
    margin: 50px 0 40px;
  }

  .breadcrumbs__link,
  .breadcrumbs__link--back{
    display: inline-block;
    color: #d43b12;
    font-size: 16px;
    line-height: 24px;
  }