/* services listing
************************/

.services__lst{
  overflow: hidden;
}
  .services__lst__title{
    margin: 43px 0 36px;
    font-size: 32px;
    line-height: 1;
    text-align: left;
    font-weight: 300;
    color: #d43b12;
    letter-spacing: -1px;
  }

  .services__lst__item{
    background-size: cover !important;


    border-radius: 3px;
    margin-bottom: 40px;
    background: #ccc;
    min-height: 140px;
    position: relative;
  }

    .services__lst__img{
      height: 70px;
      border-radius: $rad $rad 0 0;
    }

    .services__lst__mask {
      position: absolute;
      padding: 0 20px;
      background: #3e343a;
      min-height: 70px;
      height: 70px;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 0 0 3px 3px;

      -webkit-transition-property: height;
      -webkit-transition-duration: 0.8s;
      -webkit-transition-timing-function: ease;
      -moz-transition-property: height;
      -moz-transition-duration: 0.8s;
      -moz-transition-timing-function: ease;
      -o-transition-property: height;
      -o-transition-duration: 0.8s;
      -o-transition-timing-function: ease;
      transition-property: height;
      transition-duration: 0.8s;
      transition-timing-function: ease;

      &::after{
        content: '';
        position: absolute;
        left: 28px;
        top: -21px;
        border: 9px solid transparent;
        border-bottom: 12px solid #3e343a;
      }
    }
    
        .mask__title{
          font-size: 16px;
          line-height: 1.2;
          font-weight: 700;
          color: #fff;
          padding: 15px 0 0 0;
        }

        .mask__desc{
          display: none;
          -webkit-transition-property: display;
          -webkit-transition-duration: 2s;
          -webkit-transition-delay: 5s;
          -webkit-transition-timing-function: ease;
          -moz-transition-property: display;
          -moz-transition-delay: 5s;
          -moz-transition-duration: 2s;
          -moz-transition-timing-function: ease;
          -o-transition-property: display;
          -o-transition-delay: 5s;
          -o-transition-duration: 2s;
          -o-transition-timing-function: ease;
          transition-property: display;
          transition-delay: 5s;
          transition-duration: 2s;
          transition-timing-function: ease;
        }

        .mask__price{
          position: absolute;
          bottom: 0;
          background: url("/img/price-bg.png") no-repeat left center;
          font-size: 16px;
          line-height: 1.2;
          font-weight: 300;
          color: #fff;
          opacity: 0.7;
          padding-left: 21px;
        }

    .services__lst__item:hover{
      cursor: pointer;

      .services__lst__mask {
        height: 100%;
        width: 100%;
        border-radius: 3px;

        &::after{
          content: '';
          border: none;
        }

        .mask__desc{
          display: block;
          font-size: 12px;
          line-height: 1.2;
          font-weight: 300;
          color: #fff;
          opacity: 0.7;
        }

      }


      
    }