/* succes */
.succes{
	overflow: hidden;
	min-height: 500px;
}

	.succes__title{
		margin-top: 40px;
		font-size: 40px;
		line-height: 1.2;
		font-weight: 700;
		color: #d43b12;
	}

	.succes__info{
		margin-bottom: 40px;
		font-size: 16px;
		line-height: 1;
		color: #5c5b5b;
	}

	.to-main{
		text-decoration: none;
	}