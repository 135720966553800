/* employees
***************************/
.employees{
  overflow: hidden;
}

  .employees__item {
    max-height: 250px;
    overflow: hidden;
    margin-bottom: 40px;
  }

    .employees__title{
      margin-bottom: 30px;
      color: #d43b12;
      font-size: 30px;
      line-height: 40px;
      font-weight: 300;
    }

    .employer__img{
      min-width: 100%;
      width: 100%;
      height: auto;
      opacity: 0.88;
      border-radius: $rad;
    }

    .employer__title{
      font-size: 14px;
      line-height: 1.2;
      font-style: italic;
      font-weight: 300;
      color: #3e343a;
      margin-top: 15px;
      opacity: 0.75;
    }

    .employees__desc{
      font-size: 16px;
      line-height: 1.5;
      font-weight: 300;
      margin-bottom: 20px;
      color: #605f5f;

      &:last-child{
        margin-bottom: 0;
      }
    }