/* header__inner
********************/

.header__inner{
  background: url("/img/inner_header.jpg") #3e343a;
  background-position: center center;
  min-height: 250px;
}

  .header__inner__title {
    color: #fff;
    opacity: 0.7;
    font-size: 36px;
    line-height: 40px;
    font-weight: 300;
    height: 160px;
    margin-bottom: 0;
    display: table-cell;
    vertical-align: middle;
    position: relative;
    margin: 0;
    top: 90px;
  }