@media (min-width: 768px) and (max-width: 991px) {

  .header-logo {
    margin-top: 15px;
    width: 100%;
    height: auto;
  }
  .main-menu__item{
    margin-right: 10px;
  }

  .navbar-brand{
    width: 200px;
  }

  .logo--footer {
    margin-top: 15px;
    margin-bottom: 25px;
  }

//  .navbar-header {
//    float: none;
//  }
//  .navbar-toggle {
//    display: block;
//  }
//
//  .navbar-collapse{
//    padding: 0;
//  }
//
//  .collapse{
//    visibility: hidden;
//    margin: 0 -30px;
//    padding: 0 45px 0 30px;
//  }
//
//  .collapse.in{
//    visibility: visible;
//    background: #201215;
//    margin: 0 -24px;
//    padding: 0 45px 0 30px;
//    opacity: 0.8;
//  }
}