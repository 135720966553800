/* contacts
*************/

.maps{
  margin-bottom: 10px;
}

.contact-info{
  overflow: hidden;
}

  .contact-info__title{
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    color: #d43b12;
    margin: 30px 0;
  }

  .contact-info__item{
    margin-bottom: 50px;
    padding-left: 86px;
  }

  .contact-info__item--phones{
    background-image: url("/img/phone.png");
    background-repeat: no-repeat;
    background-position: left top;
  }

  .contact-info__item--mark{
    background-image: url("/img/marc.png");
    background-repeat: no-repeat;
    background-position: left top;
  }

  .contact-info__item--time{
    background-image: url("/img/time.png");
    background-repeat: no-repeat;
    background-position: left top;
  }

    .contact-info__phone-number{
      margin: 0;
      font-size: 16px;
      line-height: 18px;
      color: #3e343a;
      font-weight: 300;
    }

    address{
      margin-bottom: 10px;
      font-size: 16px;
      color: #3e343a;
    }

    .contact-info__time-work{
      margin-bottom: 34px;
      color: #3e343a;
    }

    .contact-info__call-back,
    .contact-info__exit-master,
    .contact-info__malfunction{
      font-size: 14px;
      line-height: 18px;
      color: #7f6b77;
      font-weight: 300;
    }
