// Main styles //
$textColor: #605f5f;
$linkColor: #d43b12;

$rad: 3px; 


/* main
====================== */
::-webkit-input-placeholder {color:#727272;}
::-moz-placeholder          {color:#727272;}/* Firefox 19+ */
:-moz-placeholder           {color:#727272;}/* Firefox 18- */
:-ms-input-placeholder      {color:#727272;}

img{
  border-radius: $rad;
}

a{
  color: $linkColor;
  text-decoration: underline;
       -webkit-transition: color 0.5s ease-out 0.2s;
     -moz-transition: color 0.5s ease-out 0.2s;
     -o-transition: color 0.5s ease-out 0.2s;
     transition: color 0.5s ease-out 0.2s;

  &:hover{
    text-decoration: none;
    color: $linkColor;
  }

  &:focus{
    color: $linkColor;
    outline: none;
    text-decoration: none;
  }
}

  .ul-col-2{
    -webkit-columns: 2;
    -webkit-column-gap: 35px;
    -moz-columns: 2;
    -moz-column-gap: 35px;
    columns: 2;
    column-gap: 35px;
  }

  .ul-col-3{
    -webkit-columns: 3;
    -webkit-column-gap: 35px;
    -moz-columns: 3;
    -moz-column-gap: 35px;
    columns: 3;
    column-gap: 35px;
  }

.mt50{
  margin-top: 50px;
}

.btn{
  width: 100%;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 300;
  border-radius: $rad;
  padding: 13px;
}

.btn-orange{
  background: #d43b12;
  color: #fff;
       -webkit-transition: background 0.5s ease-out 0.2s;
     -moz-transition: background 0.5s ease-out 0.2s;
     -o-transition: background 0.5s ease-out 0.2s;
     transition: background 0.5s ease-out 0.2s;

  &:hover{
    background: #FE7753;
  }
}

.btn-orange:hover,
.btn:focus,
.btn:active:focus{
  color: #fff;
  outline: none;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Roboto', sans-serif;
}

body{
	font-family: 'Roboto', sans-serif;
	color: $textColor;
}

label{
  font-size: 16px;
}

.form-group{
  margin-bottom: 20px;
}

.form-control{
  height: 40px;
  border-color: #fff;
  border-radius: $rad;
  padding: 4px 12px 0;
  font-size: 16px;
  font-weight: 300;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.disc--gray{
display: inline-block;
width: 6px;
height: 6px;
background: #d4d3d3;
margin-right: 5px;
border-radius: 50%;
}

@import "layout/header";
@import "layout/header__inner";
@import "layout/main-slider";
@import "layout/main-content";
@import "layout/advantages__lst";
@import "layout/services__lst";
@import "layout/sidebar";
@import "layout/employees";
@import "layout/blog";
@import "layout/pagination";
@import "layout/application";
@import "layout/device__lst";
@import "layout/breadcrumbs";
@import "layout/contact";
@import "layout/prices";
@import "layout/404";
@import "layout/succes";
@import "layout/footer";


/* responsive */
@import "responsive/md";
@import "responsive/sm";
@import "responsive/xs";