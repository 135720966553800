/* prices
***********/

.prices__lst{
  overflow: hidden;
}

  .prices__lst__title{
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    color: #d43b12;
    margin: 30px 0;
  }

  .table > tbody > tr > td{
    border: none;
    vertical-align: middle;
    padding: 0 15px;
  }

  .price__lst__table{
    overflow: hidden;
    font-size: 16px;
    line-height: 1.2;
    border-bottom: 1px solid #ccc;
   }

  .price__lst__table > tbody > tr{
    border-top: 1px solid #e5e3e3;
  }

  .price__lst__table > tbody > tr > td:first-child{
    padding: 27px 15px;
  }

  .price__lst__table > tbody > tr > td:first-child > a{
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #3e343a;
    text-decoration: none;
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: 3px;

    &:hover{
      color: #D43B12;
    }
  }

  .price__lst__table > tbody > tr > td:first-child > a:hover{
    
  }

  .price__lst__table > tbody > tr > td >button{
    padding: 10px;
  }

  #anketa{
    display: none;
  }









