/*  main content
********************/

.main-content{
  overflow: hidden;
//  margin-bottom: 60px;
}

.mr15{
  margin-right: 15px;
}

article{
  overflow: hidden;

  figure{
    margin-bottom: 25px;

    >img{
      min-width: 100%;
      width: 100%;
      height: auto;
      opacity: 0.88;
    }

    figcaption{
      font-size: 14px;
      line-height: 1.2;
      font-style: italic;
      font-weight: 300;
      color: #3e343a;
      margin-top: 15px;
      opacity: 0.75;
    }
  }

  >img{
    margin: 0 30px 30px 30px;
  }

  .img-left{
      float: left;
      margin-top: 15px;
      margin-left: 0;
  }

  .img-right{
     float: right;
      margin-top: 15px;
      margin-right: 0;
  }

  h2, h3, h4, h5, h6{
    margin-bottom: 25px;
//    padding: 0 15px;
  }

  h4{
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
  }

  >p{
    padding: 0 15px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    margin-bottom: 30px;
    color: #605f5f;

    >img{
      margin: 0 30px 30px 30px;
    }

    .img-left{
      float: left;
      margin-top: 15px;
      margin-left: 0;
    }

    .img-right{
      float: right;
      margin-top: 15px;
      margin-right: 0;
    }

    >a{
      color: $linkColor;
      text-decoration: underline;
    }

  }

  ul{
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 30px;
    clear: both;
    padding: 0 20px;

    li{
      color: #333333;    font-size: 16px;
      line-height: 1.5;
      font-weight: 300;

      >a{
        color: $linkColor;
        text-decoration: underline;
      }
    }
  }

  .problems__lst{
    overflow: hidden;
  }
    .problems__item{
      padding-bottom: 15px;
      > a{
        padding-bottom: 2px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        color: #5c5b5b;
        border-bottom: 1px solid #d2d2d2;
        text-decoration: none;

        &:hover{
          color: #d43b12;
        }
      }
    }

  .ul-col-2{
    -webkit-columns: 2;
    -webkit-column-gap: 35px;
    -moz-columns: 2;
    -moz-column-gap: 35px;
    columns: 2;
    column-gap: 35px;
  }

  .ul-col-3{
    -webkit-columns: 3;
    -webkit-column-gap: 35px;
    -moz-columns: 3;
    -moz-column-gap: 35px;
    columns: 3;
    column-gap: 35px;
  }
}

  .main-content__title{
    font-size: 30px;
    line-height: 1.2;
    font-weight: 300;
    color: #d43b12;
    margin: 30px 0;
  }
  
