/* device__lst
***************/

.device__lst{
  overflow: hidden;
  /* margin-bottom: 20px; */
}

  .device__lst__title{
    font-size: 30px;
    line-height: 1.2;
    font-weight: 300;
    color: #d43b12;
    margin: 30px 0;
  }

  .device__item{
      margin-bottom: 30px;
      min-height: 160px;
  }

  .device__img__wrap-img{
    min-height: 48px;
    margin-bottom: 13px;
  }

  .device__img{

  }

  .device__device__lst-models{
    font-size: 14px;
    line-height: 116px;
    color: #3e343a;
  }

  .device__manufacturer{
    padding-bottom: 2px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    border-bottom: 1px solid #d2d2d2;
    color: #5c5b5b;
    text-decoration: none;


    &:hover{
      color: #d43b12;
    }
  }

  .device__all-manufacturer{
    text-align: center;
  }

    .device__all-manufacturer__link{
      font-size: 18px;
      line-height: 30px;
      font-weight: 700;
      color: #3e343a;
      padding-bottom: 3px;
      border-bottom: 1px solid #d5d2d4;
      text-decoration: none;
    }











































