/* 404 */
.error-404{
	overflow: hidden;
}

	.error-404__title{
		margin: 30px 0 0 0;
		font-size: 100px;
		line-height: 1;
		font-weight: 700;
		color: #d43b12;
	}

	.error-404__page-not-found{
		margin-bottom: 20px;
		font-size: 40px;
		line-height: 1;
		font-weight: 700;
		color: #666666;
	}

	.error-404__sorry{
		margin-bottom: 25px;
		font-size: 16px;
		line-height: 24px;
		color: #5c5b5b;
	}

	.error-404__why{
		margin-bottom: 5px;
		font-size: 16px;
		line-height: 24px;
		color: #5c5b5b;
	}

	.error-404__problems__lst{
		overflow: hidden;
		padding-bottom: 45px;
	}

		.error-404__problems__item{
			font-size: 16px;
			line-height: 24px;
			color: #5c5b5b;
			
			>a{
				font-size: 16px;
				line-height: 24px;
				color: #5c5b5b;
			}
		}

