/* swiper
*****************/

.swiper-slide{
  height: 510px;
}

.nav-slider-btns{
  position: relative;
}

.slider__desc{
  overflow: hidden;
  height: 160px;
  margin-top: 165px;
  margin-bottom: 25px;
  font-size: 36px;
  line-height: 40px;
  font-weight: 300;
  color: #fff;
  opacity: 0.7;
}

.slider__link{
  position: relative;
  top: 0;
  text-decoration: none;
}

.disc{
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
  top: -3px;
  position: relative;
  margin-right: 5px;
}

.swiper-container{
  background: #3e343a;
}

.swiper-button-next, .swiper-button-prev{
   margin-top: -51px;
  -webkit-background-size: auto;
  background-size: auto;
  width: 12px;
  height: 21px;
 }

.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
  right: auto;
  left: 82px;
}

.swiper-button-next{
  background-image: url("/img/next.png");
}

.swiper-button-prev{
  background-image: url("/img/prew.png");
  left: 15px;
}