/* sidebar
******************/

.sidebar{
  overflow: hidden;
  background: #3e343a;
  margin-top: 25px;
  border-radius: $rad;
}

  .sidebar__contact__title,
  .sidebar__send-form__title{
    font-size: 18px;
    line-height: 1.2;
    font-weight: 300;
    color: #fe7753;
    padding: 0 20px;
  }

  .sidebar__contact__desc{
    font-size: 14px;
    line-height: 1.2;
    color: #fff;
    opacity: 0.7;
    font-weight: 300;
    padding: 0 20px;
  }

    .sidebar__contact__desc__link {
      font-size: 12px;
      color: #fff;
      font-weight: 300;
      text-decoration: underline;

      &:hover{
        color: #fff;
        font-weight: 300;
        text-decoration: none;
      }

      &:focus{
        color: #fff;
      }
    }

  .sidebar__phone__lst{
    overflow: hidden;
    margin-top: 20px;
  }

    .sidebar__phone__item{
      color: #fff;
      margin-left: 40px;
      list-style-type: disc;
      opacity: 0.7;
      font-size: 18px;
      line-height: 1.2;
      font-weight: 300;
    }

  .sidebar__send-form__title{
    margin-bottom: 25px;
    letter-spacing: -0.5px;
  }

  hr{
    border-color: #534a4f;
  }

   .sidebar__form{
     padding: 0 20px 20px;
   }

#fixed{
  position: absolute;
  width: 262.5px;
  z-index: 99;
}
.fixed {
  position: fixed;
  top: 10px;
  width: 262.5px;
  z-index: 9999;
}


.gl-contact{
  position: absolute;
}



























