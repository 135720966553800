/* header
********************/

.header {
  width: 100%;
  position: absolute;
  z-index: 9;
}

.navbar{
  margin-bottom: 0;
}

.navbar-default{
  background-color: transparent;
  border: none;
}

.navbar-toggle{
  margin-bottom: 30px;
  margin-top: 25px;
}
.navbar-brand{
  height: auto;
}

.header-logo{
  margin-top: 10px;
}

  .header__phone__lst{
    overflow: hidden;
    float: left;
    margin-top: 15px;
    margin-left: 35px;
  }

    .header__phone__item{
      font-size: 14px;
      line-height: 20px;
      color: #bf4f31;
      font-weight: 300;

      >span {
        color: #fe7753;
      }
    }



    .main-menu{
      overflow: hidden;
      font-size: 0;
      text-align: right;
      margin-top: 35px;
    }

      .main-menu__item{
        display: inline-block;
        padding-bottom: 4px;
        border-bottom: 1px solid #fe7753;
        margin-right: 40px;

        &:last-child{
          margin-right: 0;
        }

        &.active{
          border: none;
          
          >a,
          span{
            color: #fff;
          }
        }

        &:hover{
          a{
            color: #D43B12;
          }
        }
        
        .main-menu__link{
          text-decoration: none;
          font-size: 16px;
          line-height: 1.2;
          font-weight: 700;
          color: #fe7753;
        }

      }

.top-line{
  background: #201215;
  position: absolute;
  z-index: 8;
  opacity: 0.8;
  height: 90px;
  width: 100%;
}

.top-line--inner{
  opacity: 0.6;
}