/* advantages__lst
************************/

.advantages__lst{
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 20px;

}

  .advantages__item{
    width: 100%;
    display: table;
    overflow: hidden;
    border: $rad;
    background: #392e34;
    color: #fff;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 700;
    height: 98px;
    margin-bottom: 20px;
    border-radius: $rad;
  }

  .advantages__item--cpu{
    background-image: url("/img/cpu.png");
    background-repeat: no-repeat;
    background-position: 25px center;
  }

  .advantages__item--clock{
    background-image: url("/img/clock.png");
    background-repeat: no-repeat;
    background-position: 25px center;
  }

  .advantages__item--auto{
    background-image: url("/img/auto.png");
    background-repeat: no-repeat;
    background-position: 25px center;
  }


  .advantage__title{
    display: table-cell;
    vertical-align: middle;
      padding: 0 20px 0 93px;
      font-size: 18px;
      line-height: 1.2;
      font-weight: 400;
      letter-spacing: -0.5px;
   }



  /* Strong */

.advantages__lst--strong{
  overflow: hidden;
  margin-bottom: 30px;
}

.advantages__item--strong{
  height: 130px;
  display: table;
}

.advantages__item--strong--cpu{
  background-position: 42px center;
}

.advantages__item--strong--clock{
  background-position: 42px center;
}

.advantages__item--strong--auto{
  background-position: 42px center;
}

.advantage__title--strong{
  display: table-cell;
  vertical-align: middle;
  padding: 0 60px 0 125px;
}


/* horisontal */

.advantages__horisontal{
  overflow: hidden;
}

  .advantages__horisontal__title{
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    color: #d43b12;
    margin: 30px 0;
  }

  .advantages__horisontal__item{
    padding-bottom: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #f6d4cb;

    &:first-child{
      margin-top: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }

    .advantages__horisontal__item__img{
      margin-top: 40px;
      min-width: 100%;
      width: 100%;
      height: 189px;
      border-radius: $rad;
    }

    .advantages__horisontal__item__title{
      margin: 40px 0 15px 0;
      font-size: 18px;
      line-height: 30px;
      font-weight: 700;
    }

    .advantages__horisontal__item__desc{
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #333;
    }





































