/* application
***********************/

.application{
  overflow: hidden;
}

  .application__title{
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    color: #d43b12;
    letter-spacing: -0.5px;
  }

  .application__desc{
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    margin-bottom: 40px;
    color: #605f5f;
  }

  .application__form{
    margin-bottom: 50px;

    .form-control{
      border: 1px solid #a4a3a3;
    }
  }

  .red{
    color: #d43b12;
  }

  .application__form__item-info{
    margin-top: 25px;
    font-size: 12px;
    line-height: 1.2;
    color: #333;
    opacity: 0.75;
    font-style: italic;
    font-weight: 300;
  }